import React from "react"
import { Background } from "react-imgix"
import tw from "twin.macro"
import Banner from "./Banner"

const ImgBanner = tw.div`
  static
`

export default function BgImgBanner({ className, tagLine, headline, shortDescription, lowResImage, highResImage }) {
  return (
    <ImgBanner>
      <Background className={`lazyload blur-up min-h-screen w-full ${className}`}
                  src={highResImage}
                  sizes="100vw"
                  attributeConfig={{
                    src: "data-src",
                    srcSet: "data-srcset",
                    sizes: "data-sizes",
                    datalowsrc: "data-lowsrc"
                  }}
                  htmlAttributes={{
                    src: lowResImage,
                    datalowsrc: lowResImage,
                  }}>
        <Banner className={`${className}`}
                tagLine={tagLine}
                headline={headline}
                shortDescription={shortDescription}/>
      </Background>
    </ImgBanner>
  )
}