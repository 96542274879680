import React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import Layout from "../components/Layout"
import SliceZone from "../components/SliceZone"
import SEO from "../components/SEO"
import BgImgBanner from "../components/BgImgBanner"

export default function Solution({ data }) {
  if (!data) return null

  const solutionEdge = data.prismic.solution.edges[0]
  if (!solutionEdge) return null
  const content = solutionEdge.node

  const navigationEdges = data.prismic.allNavigations.edges[0]
  if (!navigationEdges) return null
  const navOptions = navigationEdges.node.menu_links
  const activePageMeta = content._meta
  if (content) {
    return (
      <Layout activePageMeta={content.page._meta}
              activeSubPageMeta={content._meta}
              navOptions={navOptions}>
        {
          content.seo_page_title && (
            <SEO lang={activePageMeta.lang}
                 title={RichText.asText(content.seo_page_title)}
                 description={RichText.asText(content.seo_page_description)}
                 social={content.social}/>
          )
        }
        <BgImgBanner className={`bg-${content.color}-600 text-white bg-opacity-75`}
                     tagLine={RichText.render(content.tagline)}
                     headline={RichText.render(content.headline)}
                     lowResImage={content.bg_image.low_res.url}
                     highResImage={content.bg_image.high_res.url}/>
        <SliceZone slices={content.body} />
      </Layout>
    )
  }
  return null
}

export const query = graphql`
query solutionQuery($uid: String, $lang: String) {
  prismic {
    solution: allSolutions(uid: $uid, lang: $lang) {
      edges {
        node {
          _meta {
            uid
            lang
            type
            alternateLanguages {
              uid
              type
              lang
            }
          }
          tagline
          headline
          color
          bg_image
          seo_page_title
          seo_page_description
          social {
            ... on PRISMIC_SolutionSocialGeneral_card {
              type
              label
              primary {
                title
                image
                description
              }
            }
            ... on PRISMIC_SolutionSocialTwitter_card {
              type
              label
              primary {
                card_type
                description
                image
                title
                twitter_handle
              }
            }
          }
          body {
            ... on PRISMIC_SolutionBodyInfo_with_color {
              type
              label
              primary {
                section_tagline
                section_headline
                section_description
                section_color
              }
            }
            ... on PRISMIC_SolutionBodyInfo_with_list {
              type
              label
              fields {
                section_list {
                  ... on PRISMIC_Service {
                    document_title
                    service_title
                    service {
                      name
                    }
                  }
                }
              }
              primary {
                section_description
                section_headline
              }
            }
          }
          page {
            ... on PRISMIC_Page {
              _meta {
                uid
                lang
                type
                alternateLanguages {
                  uid
                  type
                  lang
                }
              }
            }
          }
        }
      }
    }
    allNavigations(lang: $lang) {
      edges {
        node {
          menu_links {
            label
            link {
              ... on PRISMIC_Page {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Homepage {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`
